<template>
  <div class="user-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "房东管理",
        search: [
          {
            key: "username",
            label: "姓名"
          },
          {
            key: "phonenum",
            label: "电话号码"
          },
          {
            key: "userstate",
            label: "账号状态",
            type: "select",
            opt: {
              list: [
                {
                  label: "未激活",
                  value: '1'
                },
                {
                  label: "已激活",
                  value: '2'
                }
              ]
            }
          }
        ],
        columns: [
          {
            label: "姓名",
            key: "realname",
            width: 120,
            on: row => {
              _this.$router.push("/main/visit/detail/show/" + row.userid);
            }
          },
          { label: "身份证号码", key: "idcardno" },
          { label: "电话", key: "phonenum" },
          { label: "房源", key: "countStr" },
          { label: "紧急联系电话", key: "emergencyPhoneNum" },
          { label: "账号状态", key: "userstateStr"}
        ],
        buttons: [
          {
            type: 1,
            on(data) {
              _this.$router.push(
                "/main/visit/detail/edit/" + data.data.userid
              );
            }
          },
          {
            type: 1,
            color: "danger",
            name: "删除",
            on(data) {
              _this.$confirm("此操作将删除选择项, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
              })
                .then(() => {
                  var dto = {
                    userid: data.data.userid
                  };
                  _this
                    .post("user-service/apartmentuser/deleteApartmentuser", dto)
                    .then(function() {
                      _this.$message({
                        showClose: true,
                        message: "删除成功!",
                        type: "success"
                      });
                      data.refresh();
                    });
                })
                .catch(function() {});
            }
          }
        ]
      }
    };
  },
  created() {
    console.log("user-list created!!");
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        ...opt.searchForm
      };
      this.post("user-service/apartmentuser/selectApartmentUser", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.countStr = item.count + '个'
          item.userstateStr = ["未激活", "已激活", "失效", "删除"][
            item.userstate - 1
          ];
          if (item.userstate == '1') {
            item.bk = 2;
          } else {
            item.bk = 0;
          }
        }
        opt.cb(res.data);
      });
    }
  }
};
</script>